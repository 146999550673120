<template>
  <div>
    <v-data-table
      :loading="loading"
      loading-text="Please wait.... Loading"
      :headers="headers"
      :items="items"
      hide-default-footer
      mobile-breakpoint
      class="elevation-3 rounded-t-0"
      @dblclick:row="onItemDoubleClick"
      @contextmenu:row="onRightClick"
    >
      <template v-slot:[`item.created`]="{ item }">
        {{ $utils.formatDate(item.modified) }}
      </template>

      <template v-slot:[`item.case_id`]="{ item }">
        {{ item.case.case_id }}
      </template>

      <template v-slot:[`item.client`]="{ item }">
        <div>{{ item.case.client.name }}</div>
        <div>{{ item.case.client.email }}</div>
        <div>{{ item.case.client.mobile }}</div>
      </template>

      <template v-slot:[`item.days_extension`]="{ item }">
        {{ item.days_extension }}
        {{ item.days_extension > 1 ? "Days" : "Day" }}
      </template>

      <template v-slot:[`item.tat_reason`]="{ item }">
        <div class="text-body-2 text--secondary">Client Reason</div>
        {{ item.client_reason }}
        <div class="mt-2">
          <div class="text-body-2 text--secondary">Internal Reason</div>
          {{ item.internal_reason }}
        </div>
      </template>

      <template v-slot:[`item.status_display`]="{ item }">
        <v-chip
          small
          class="lighten-5 text--darken-2 font-weight-bold text-capitalize"
          :class="
            item.status == 'verified' || item.status == 'successful'
              ? 'success success--text'
              : item.status == 'pending'
              ? 'blue blue--text'
              : 'error error--text'
          "
        >
          {{ item.status_display }}</v-chip
        >
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex justify-center" style="gap: 10px">
          <v-btn
            small
            outlined
            height="32px"
            color="primary"
            class="text-capitalize rounded-lg"
            v-if="item.actions.includes('approve_tat_change')"
            @click="openConfirmationDialog(item, 'approve')"
          >
            Approve
          </v-btn>
          <v-btn
            small
            outlined
            height="32px"
            color="primary"
            class="text-capitalize rounded-lg"
            v-if="item.actions.includes('reject_tat_change')"
            @click="openConfirmationDialog(item, 'reject')"
          >
            Reject
          </v-btn>
          <v-btn
            small
            outlined
            height="32px"
            color="primary"
            class="text-capitalize rounded-lg"
            v-if="item.actions.includes('view_case')"
            @click="openCaseDetail(item)"
          >
            View Case
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-container
      class="d-flex justify-space-between align-center px-0 mb-0 mt-4"
      fluid
    >
      <div class="d-flex align-center text-subtitle-2">
        <div class="mr-2">Rows per page</div>
        <div style="width: 75px">
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            :items="itemsPerPageList"
            v-model="page_count"
            hide-details
            dense
            solo
            color="primary"
            class="rounded-lg"
          ></v-select>
        </div>
        <div class="ml-6">
          Results: {{ pageStart }} - {{ pageEnd }} of {{ total_count }}
        </div>
      </div>
      <v-pagination
        v-model="page_number"
        :length="page_total"
        :total-visible="5"
        color="primary"
      >
      </v-pagination>
    </v-container>

    <v-menu
      v-model="rightClickMenu.show"
      :position-x="rightClickMenu.positionX"
      :position-y="rightClickMenu.positionY"
      transition="scale-transition"
      min-width="150"
      absolute
      offset-y
    >
      <v-list
        nav
        dense
        :outlined="$vuetify.theme.dark"
        v-if="selectedItem && selectedItem.actions.length"
      >
        <v-list-item link v-if="selectedItem.actions.includes('view_case')">
          <v-list-item-title @click="onRightClickMenuSelected('view_case')">
            View Case
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          link
          v-if="selectedItem.actions.includes('approve_tat_change')"
        >
          <v-list-item-title @click="onRightClickMenuSelected('approve')">
            Approve
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          link
          v-if="selectedItem.actions.includes('reject_tat_change')"
        >
          <v-list-item-title @click="onRightClickMenuSelected('reject')">
            Reject
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <ConfirmationDialog @confirmed="confirmed" @cancelled="cancelled">
      <template v-slot:confirmBtnText> Yes {{ confirmation_type }} </template>
      <template v-slot:form v-if="confirmation_type === 'reject'">
        <v-form lazy-validation ref="form" class="mb-2 mx-2">
          <v-row no-gutters>
            <v-col cols="12">
              <v-select
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
                append-icon="mdi-chevron-down"
                v-model="form.reason_id"
                :items="reason_list"
                item-text="title"
                item-value="id"
                flat
                outlined
                dense
                label="Select Reason"
                :rules="[$rules.required]"
                class="rounded-lg"
              ></v-select>
            </v-col>
            <v-col cols="12" v-if="form.reason_id === 0">
              <v-textarea
                flat
                :rows="3"
                outlined
                v-model="form.reason_other"
                label="Type here..."
                :rules="[$rules.required]"
                class="rounded-lg"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </ConfirmationDialog>
  </div>
</template>
<script>
import header from "../../../common/tatHeader/tatHeader";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import ConfirmationDialog from "@/components/Dialogs/ConfirmationDialog";

export default {
  components: { ConfirmationDialog },
  name: "tatTable",
  props: ["activeTab"],
  data() {
    return {
      items: [],
      loading: false,
      page_total: 1,
      page_number: 1,
      total_count: 0,
      itemsPerPageList: [5, 10, 15, 20, 30, 40, 50],
      page_count: 10,
      clickSameTab: true,
      selectedItem: null,
      confirmation_type: null,
      reason_list: [],
      form: {
        reason_id: null,
        reason_other: "",
      },
      rightClickMenu: {
        show: false,
        positionX: 0,
        positionY: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      getSearch: "tatManagement/getSearch",
      getReason: "tatManagement/getReason",
      getDate: "tatManagement/getDate",
    }),
    pageStart() {
      let start = (this.page_number - 1) * this.page_count + 1;
      if (start + 1 > this.total_count) start = this.total_count;
      return start;
    },
    pageEnd() {
      let end = this.pageStart + this.page_count - 1;
      if (end > this.total_count) end = this.total_count;
      return end;
    },

    headers() {
      switch (this.activeTab) {
        case "pending":
          return header.pending;
        case "successful":
          return header.approved;
        case "rejected":
          return header.rejected;
        default:
          return null;
      }
    },
  },

  watch: {
    getSearch() {
      this.debounceGetLeadList();
    },
    getReason() {
      this.getListData();
    },
    getDate() {
      this.getListData();
    },
    activeTab() {
      this.getListData();
    },
    page_count() {
      this.getListData();
    },
    page_number() {
      this.debounceGetLeadList();
    },
  },
  created() {
    this.$root.$refs.tat = this;
    this.debounceGetLeadList = _.debounce(function () {
      this.getListData();
    }, 500);
  },
  mounted() {
    if (this.clickSameTab) this.getListData();
  },

  methods: {
    ...mapActions({
      showSnackbar: "app/showSnackbar",
      toggleConfirmationDialog: "dialogs/toggleConfirmationDialog",
      setConfirmationDialogBtnLoading:
        "dialogs/setConfirmationDialogBtnLoading",
    }),
    sameTabReload() {
      this.clickSameTab = false;
      this.getListData();
    },

    getListData() {
      this.loading = true;
      this.items = [];

      const onSuccess = (res) => {
        this.items = res.data.data;
        this.page_total = res.data.page_info.page_total;
        this.page_number = res.data.page_info.page_number;
        this.total_count = res.data.page_info.total_count;
        this.$emit("reload-count");
      };

      const onFinally = () => {
        this.loading = false;
      };

      let params = {};
      params["page_number"] = this.page_number;
      params["page_count"] = this.page_count;
      params["tat_change_request_status"] = this.activeTab;
      if (this.getSearch) params["search"] = this.getSearch;
      if (this.getDate) params["date"] = this.getDate;
      if (this.getReason) params["reason_id"] = this.getReason;

      return this.$request(
        this.$keys.GET,
        this.$urls.case.tatChangeRequest.list,
        { params, onSuccess, onFinally }
      );
    },

    openCaseDetail(item) {
      this.$router.push({
        name: "caseDetail",
        params: {
          id: item.case.id,
        },
      });
    },

    openConfirmationDialog(item, type) {
      this.selectedItem = item;
      this.confirmation_type = type;
      if (type === "reject") this.getReasonList();
      this.toggleConfirmationDialog({ show: true, action: type });
    },

    confirmed() {
      if (this.confirmation_type === "approve") this.approve();
      if (this.confirmation_type === "reject") this.reject();
    },

    cancelled() {
      if (this.$refs.form) this.$refs.form.reset();
    },

    approve() {
      const onSuccess = () => {
        this.getListData();
        this.toggleConfirmationDialog({ show: false });
      };

      const onFinally = () => {
        this.setConfirmationDialogBtnLoading({ loading: false });
      };

      let data = {
        tat_change_request_id: this.selectedItem.id,
      };

      return this.$request(
        this.$keys.POST,
        this.$urls.case.tatChangeRequest.approve,
        {
          data,
          onSuccess,
          onFinally,
        }
      );
    },

    reject() {
      if (this.$refs.form.validate()) {
        const onSuccess = () => {
          this.getListData();
          this.toggleConfirmationDialog({ show: false });
        };

        const onFinally = () => {
          this.setConfirmationDialogBtnLoading({ loading: false });
        };

        let data = {
          tat_change_request_id: this.selectedItem.id,
        };

        if (this.form.reason_id) data["reason_id"] = this.form.reason_id;
        if (this.form.reason_other)
          data["reason_other"] = this.form.reason_other;

        return this.$request(
          this.$keys.POST,
          this.$urls.case.tatChangeRequest.reject,
          {
            data,
            onSuccess,
            onFinally,
          }
        );
      }
    },

    getReasonList() {
      const onSuccess = (res) => {
        this.reason_list = res.data.data;
        this.reason_list.push({
          id: 0,
          title: "Other Reason",
          type: "other",
        });
      };

      let params = {};
      params["reason_type"] = "reject";

      return this.$request(this.$keys.GET, this.$urls.master.reason.list, {
        params,
        onSuccess,
      });
    },

    onItemDoubleClick(event, { item }) {
      this.openCaseDetail(item);
    },

    onRightClick(event, { item }) {
      event.preventDefault();
      this.selectedItem = item;
      this.rightClickMenu.show = false;
      this.rightClickMenu.positionX = event.clientX;
      this.rightClickMenu.positionY = event.clientY;
      this.$nextTick(() => {
        this.rightClickMenu.show = true;
      });
    },

    onRightClickMenuSelected(action) {
      switch (action) {
        case "view_case":
          this.openCaseDetail(this.selectedItem);
          break;
        case "approve":
          this.openConfirmationDialog(this.selectedItem, "approve");
          break;
        case "reject":
          this.openConfirmationDialog(this.selectedItem, "reject");
          break;
        default:
          break;
      }
    },
  },
};
</script>
